<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" width="200px">
				<div style="padding: 30px 20px;">
					<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item "
						:class="{on:getSelectKey.indexOf(item.key) > -1}">
						<i class="iconfont ft14" :class="item.icon"></i>
						<span class="ft14 ml10">{{item.name}}</span>
					</div>
				</div>
			</a-layout-sider>
			<a-layout-content style="padding: 30px 20px;height: 100vh; background: #F7F8FA;">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>

	</div>
</template>

<script>
	export default {

		data() {
			return {
				showForm: false,

				menus: [{
						name: '项目管理',
						icon: 'iconproject',
						key: 'project_index',
						link: '/project'
					},
					// {
					// 	name: '抢购管理',
					// 	icon: 'iconqianggou',
					// 	key: 'project_snapup',
					// 	link: '/project/snapup'
					// },
					{
						name: '次卡管理',
						icon: 'iconcountingcard',
						key: 'project_oncecard',
						link: '/project/oncecard'
					},
					// {
					// 	name: '抢购订单',
					// 	icon: 'iconmall_order',
					// 	key: 'project_orderSnapup',
					// 	link: '/project/orderSnapup'
					// },
					{
						name: '次卡订单',
						icon: 'iconoperation_cate031',
						key: 'project_orderOncecard',
						link: '/project/orderOncecard'
					},
					// {
					// 	name: '预约设置',
					// 	icon: 'icontabbar041',
					// 	key: 'project_yuyue',
					// 	link: '/project/yuyue'
					// },
					// {
					// 	name: '预约列表',
					// 	icon: 'iconrecord',
					// 	key: 'project_yyorder',
					// 	link: '/project/yyorder'
					// },
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
		}
	}
</script>

<style>

</style>
